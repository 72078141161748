<template>
  <div>
  <el-dialog
      :title="dialogTitle"
      v-model="showForm"
      :before-close="closeDialog">
    <h6 class="text-dacy">{{fullInfo}}</h6>

    <el-row class="mt-4">
      <el-col :span="17">
        <h6>Therapie</h6>
        <el-select size="medium" class="w-100" v-model="currentProgress.patient_therapy_id" filterable>
          <el-option
              v-for="item in allTherapies"
              :key="item.id"
              @click="onTherapyChange(item)"
              :label="$filters.germanDate(item.date_start) + ', ' + (item.meta.therapy ? item.meta.therapy.code : '')"
              :value="item.id">
          </el-option>
        </el-select>
      </el-col>

    </el-row>
    <el-row class="mt-4">
      <el-col :span="17">
        <h6>Name der Verlaufsbeobachtung</h6>
        <el-input v-model="currentProgress.title"></el-input>
      </el-col>
    </el-row>
    <el-row class="mt-4">
      <el-col :span="8">
        <h6>Beginn</h6>
        <el-date-picker
            class="w-100"
            v-model="currentProgress.date_start"
            type="date"
            format="DD.MM.YYYY"
            value-format="YYYY-MM-DD"
            placeholder="Datum auswählen">
        </el-date-picker>
      </el-col>
      <el-col :span="8" :offset="1">
        <h6>Ende</h6>
        <el-date-picker
            class="w-100"
            v-model="currentProgress.date_end"
            type="date"
            format="DD.MM.YYYY"
            value-format="YYYY-MM-DD"
            placeholder="Datum auswählen">
        </el-date-picker>
      </el-col>
    </el-row>


    <template #footer>
      <el-row>

        <el-col :span="10" class="text-left">
          <el-popconfirm
              v-if="currentProgress.id"
              title="Sie möchten diese Verlaufsbeobachtung mit den Beurteilungen löschen?"
              confirmButtonText='Ja, löschen'
              confirmButtonType="danger"
              cancelButtonText='Nein'
              iconColor="red"
              @confirm="deleteProgress">
            <template #reference>
              <el-button type="danger">Löschen</el-button>
            </template>
          </el-popconfirm>&nbsp;
        </el-col>
        <el-col :span="14" class="dialog-footer text-right">
            <el-button @click="closeDialog">Abbrechen</el-button>
            <el-button type="primary" @click="saveProgress()">{{dialogSaveButtonTitle}}</el-button>
        </el-col>

      </el-row>

    </template>
  </el-dialog>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import moment from "moment";

export default {
  name: "PatientProgressForm",
  props: ['modelValue', 'show', 'progress'],
  components: {},
  data() {
    return {
      showForm: false,
      currentProgress: {},
      allTherapies: []
    }
  },
  mounted: async function () {

    this.showForm = this.modelValue
    this.currentProgress = JSON.parse(JSON.stringify(this.progress))

    await this.getTherapies()
  },
  computed: {
    ...mapGetters('patients', ['currentPatient', 'fullInfo']),
    ...mapState('therapies', ['therapies']),
    dialogTitle() {
      return (this.currentProgress && this.currentProgress.id ? 'Verlaufsbeobachtung ändern' : 'Verlaufsbeobachtung hinzufügen')
    },
    dialogSaveButtonTitle() {
      return (this.currentProgress && this.currentProgress.id ? 'Speichern' : 'Hinzufügen')
    },
    dialogSuccessMessage() {
      if(this.currentProgress && this.currentProgress.id) {
        return 'Verlaufsbeobachtung ' + this.currentProgress.title + ' bei ' + this.fullInfo + ' geändert'
      }
      else {
        return 'Verlaufsbeobachtung ' + this.currentProgress.title + ' für ' + this.fullInfo + ' hinzugefügt'
      }
    },
    dialogDeleteMessage() {
        return this.currentProgress.title + ' bei ' + this.fullInfo + ' gelöscht'
    }
  },
  watch: {
    modelValue: function (val) {
      this.showForm = val
    },
    progress: function (val) {
      this.currentProgress = JSON.parse(JSON.stringify(val))
    },
    currentPatient: function(val) {
      this.allTherapies = {...val.therapies}
    }
  },
  methods: {
    ...mapActions('therapies', ['getTherapies']),
    ...mapActions('patients', ['saveProgressToPatient', 'deleteProgressOfPatient']),
    closeDialog() {
      this.$emit('update:modelValue', false)
    },
    async saveProgress() {
      this.currentProgress.date_start = moment(this.currentProgress.date_start).format("YYYY-MM-DD")
      this.currentProgress.date_end = this.currentProgress.date_end ? moment(this.currentProgress.date_end).format("YYYY-MM-DD") : null
      await this.saveProgressToPatient(this.currentProgress)

      this.$emit('after-save')

      this.closeDialog()
      this.$notify({
        message: this.dialogSuccessMessage,
        type: 'success',
        position: 'bottom-left'
      });

    },
    async deleteProgress() {
      await this.deleteProgressOfPatient(this.currentProgress)

      this.$emit('after-save')

      this.closeDialog()
      this.$notify({
        message: this.dialogDeleteMessage,
        type: 'success',
        position: 'bottom-left'
      });

    },
    onTherapyChange(selectedTherapy) {
      this.currentProgress.date_start = selectedTherapy.date_start
    }
}
}
</script>
<style scoped>
</style>
